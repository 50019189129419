import { SubscriptionFeature } from '@/constants/subscription'
import { useSubscriptionStore } from '@/stores/subscription'
import { UserDashboard } from '@/types/user'
import { isSubscribedFeature } from '@/utils/subscription'

const parentRouteMapping: Record<string, any> = {
  [SubscriptionFeature.MEETING_MODULE]: {
    route: 'meetings-votings',
    feature: SubscriptionFeature.MEETING_MODULE,
  },
  [SubscriptionFeature.DOCUMENTS_MODULE]:
    {
      route: 'documents',
      feature: SubscriptionFeature.DOCUMENTS_MODULE,
    },
  [SubscriptionFeature.FINANCIAL_INSTRUMENT_MODULE]:
    {
      route: 'financial-instruments',
      feature: SubscriptionFeature.FINANCIAL_INSTRUMENT_MODULE,
    },
}

export default defineNuxtRouteMiddleware(async (to) => {
  const subscriptionStore = useSubscriptionStore()
  const authStore = useAuthStore()

  let isSubscribedParentFeature = true
  // Check permission based on subscription plan for issuer dashboard only
  if (authStore.currentDashboard === UserDashboard.ISSUERS) {
    const parentFeature = Object.values(parentRouteMapping).find((route: Record<string, any>) => to?.path.includes(route.route))?.feature
    isSubscribedParentFeature = isSubscribedFeature(parentFeature as string, subscriptionStore?.currentSubscriptionPlan)
  }

  if (!isSubscribedParentFeature || !isSubscribedFeature(to.meta?.feature as string, subscriptionStore?.currentSubscriptionPlan)) {
    return navigateTo({
      name: 'issuers-issuerId-company',
      params: { issuerId: String(authStore?.currentRoleId) },
      query: { activeTab: 3 },
    })
  }
})
