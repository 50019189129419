import type { SubscriptionPlan } from '@/types/subscription'

export function isSubscribedFeature(feature: string | number, subscriptionPlan?: SubscriptionPlan) {
  if (feature && subscriptionPlan?.subscription_feature_plan) {
    const plan = subscriptionPlan.subscription_feature_plan.find(plan => plan.subscription_feature.feature === feature || plan.subscription_feature.id === feature)
    if (plan) {
      return Boolean(plan.status)
    }
  }
  return true
}
