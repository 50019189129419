export const SubscriptionStatus = Object.freeze({
  TRIAL: 'TRIAL',
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  INACTIVE: 'INACTIVE',
})

export const SubscriptionPlan = Object.freeze({
  SHARE_LEDGER: 'Share Ledger',
  BASIC: 'Basic',
  PREMIUM: 'Premium',
})

export const SubscriptionAction = Object.freeze({
  UPGRADE: 'UPGRADE',
  DOWNGRADE: 'DOWNGRADE',
})

export const SubscriptionPlanSlug = Object.freeze({
  SHARE_LEDGER: 'share_ledger',
  BASIC: 'basic',
  PREMIUM: 'premium',
})

export const SubscriptionFeature = Object.freeze({
  // Overview
  OVERVIEW_MODULE: 'OVERVIEW_MODULE',

  // Investors Module
  INVESTORS_MODULE: 'INVESTORS_MODULE',
  INVESTORS_ADD_NEW: 'INVESTORS_ADD_NEW',
  INVESTORS_IMPORT: 'INVESTORS_IMPORT',
  INVESTORS_INVITE: 'INVESTORS_INVITE',
  INVESTORS_DOWNLOAD_EXCEL_DETAILS: 'INVESTORS_DOWNLOAD_EXCEL_DETAILS',
  INVESTORS_DETAILS_INFORMATION: 'INVESTORS_DETAILS_INFORMATION',

  // Financial Instrument
  FINANCIAL_INSTRUMENT_MODULE: 'FINANCIAL_INSTRUMENT_MODULE',
  FINANCIAL_INSTRUMENT_SERIES: 'FINANCIAL_INSTRUMENT_SERIES',
  FINANCIAL_INSTRUMENT_SERIES_NEW: 'FINANCIAL_INSTRUMENT_SERIES_NEW',
  FINANCIAL_INSTRUMENT_SERIES_EXPORT_EXCEL: 'FINANCIAL_INSTRUMENT_SERIES_EXPORT_EXCEL',
  FINANCIAL_INSTRUMENT_TRANSFERS: 'FINANCIAL_INSTRUMENT_TRANSFERS',
  FINANCIAL_INSTRUMENT_TRANSFERS_NEW: 'FINANCIAL_INSTRUMENT_TRANSFERS_NEW',
  FINANCIAL_INSTRUMENT_TRANSFERS_EDIT: 'FINANCIAL_INSTRUMENT_TRANSFERS_EDIT',
  FINANCIAL_INSTRUMENT_TRANSFERS_EXPORT_EXCEL: 'FINANCIAL_INSTRUMENT_TRANSFERS_EXPORT_EXCEL',
  FINANCIAL_INSTRUMENT_SHARE_LEDGER: 'FINANCIAL_INSTRUMENT_SHARE_LEDGER',
  FINANCIAL_INSTRUMENT_SHARE_LEDGER_CONSOLIDATED: 'FINANCIAL_INSTRUMENT_SHARE_LEDGER_CONSOLIDATED',
  FINANCIAL_INSTRUMENT_SHARE_LEDGER_CONSOLIDATED_EXPORT_PDF:
    'FINANCIAL_INSTRUMENT_SHARE_LEDGER_CONSOLIDATED_EXPORT_PDF',
  FINANCIAL_INSTRUMENT_SHARE_LEDGER_PER_FINANCIAL_INSTRUMENT:
    'FINANCIAL_INSTRUMENT_SHARE_LEDGER_PER_FINANCIAL_INSTRUMENT',
  FINANCIAL_INSTRUMENT_SHARE_LEDGER_PER_FINANCIAL_INSTRUMENT_EXPORT_PDF:
    'FINANCIAL_INSTRUMENT_SHARE_LEDGER_PER_FINANCIAL_INSTRUMENT_EXPORT_PDF',
  FINANCIAL_INSTRUMENT_NUMBERED_SHARE: 'FINANCIAL_INSTRUMENT_NUMBERED_SHARE', // OLD

  // Meeting-Votings Module
  MEETING_MODULE: 'MEETING_MODULE',
  MEETING_TYPE: 'MEETING_TYPE',
  MEETING_SUB_TYPE: 'MEETING_SUB_TYPE',
  MEETING_ATTENDANCE_SUMMARY: 'MEETING_ATTENDANCE_SUMMARY',
  MEETING_AGENDA_ITEM_TEMPLATE: 'MEETING_AGENDA_ITEM_TEMPLATE',
  MEETING_MINUTES: 'MEETING_MINUTES',

  // Documents
  DOCUMENTS_MODULE: 'DOCUMENTS_MODULE',
  DOCUMENTS_MANAGE_DOCUMENTS: 'DOCUMENTS_MANAGE_DOCUMENTS',
  DOCUMENTS_MANAGE_CONFIRMATIONS: 'DOCUMENTS_MANAGE_CONFIRMATIONS',
  DOCUMENT_TEMPLATE: 'DOCUMENT_TEMPLATE',

  // News and Update
  NEWS_AND_UPDATE_MODULE: 'NEWS_AND_UPDATE_MODULE',

  // Additional Services
  ADDITIONAL_SERVICES_MODULE: 'ADDITIONAL_SERVICES_MODULE',
  ADDITIONAL_SERVICES_CORPORATE_ACTIONS: 'ADDITIONAL_SERVICES_CORPORATE_ACTIONS',
  ADDITIONAL_SERVICES_MARKETPLACE: 'ADDITIONAL_SERVICES_MARKETPLACE',

  // Company Settings
  COMPANY_SETTINGS_MODULE: 'COMPANY_SETTINGS_MODULE',
})
