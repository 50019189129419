import { default as create_45passwordBuzTFjJaxsMeta } from "/builds/konsento-ag-app/frontend/pages/auth/create-password.vue?macro=true";
import { default as forgot_45passwordbDFksjZ3IkMeta } from "/builds/konsento-ag-app/frontend/pages/auth/forgot-password.vue?macro=true";
import { default as loginpiF3Uk8kVeMeta } from "/builds/konsento-ag-app/frontend/pages/auth/login.vue?macro=true";
import { default as new_45company6jDxozZZcFMeta } from "/builds/konsento-ag-app/frontend/pages/auth/new-company.vue?macro=true";
import { default as new_45registerpHtpRGnKCiMeta } from "/builds/konsento-ag-app/frontend/pages/auth/new-register.vue?macro=true";
import { default as not_45authorizedToSUByXR4uMeta } from "/builds/konsento-ag-app/frontend/pages/auth/not-authorized.vue?macro=true";
import { default as post_45registrationIoyrv7UvsmMeta } from "/builds/konsento-ag-app/frontend/pages/auth/post-registration.vue?macro=true";
import { default as register_45investor7uOw0fU8J7Meta } from "/builds/konsento-ag-app/frontend/pages/auth/register-investor.vue?macro=true";
import { default as registerHCAu1xuOn5Meta } from "/builds/konsento-ag-app/frontend/pages/auth/register.vue?macro=true";
import { default as reset_45passwordYmzC7CqWdFMeta } from "/builds/konsento-ag-app/frontend/pages/auth/reset-password.vue?macro=true";
import { default as resgiter_45co_45investmentPD8mi8tbL4Meta } from "/builds/konsento-ag-app/frontend/pages/auth/resgiter-co-investment.vue?macro=true";
import { default as verify_45emailbP3i62k4okMeta } from "/builds/konsento-ag-app/frontend/pages/auth/verify-email.vue?macro=true";
import { default as indexK92kKx2qzjMeta } from "/builds/konsento-ag-app/frontend/pages/board-member/meetings-votings/[meetingId]/index.vue?macro=true";
import { default as meeting_45minutesIcTMQhlwWWMeta } from "/builds/konsento-ag-app/frontend/pages/board-member/meetings-votings/[meetingId]/meeting-minutes.vue?macro=true";
import { default as _91id_93hAiQAQ4QQuMeta } from "/builds/konsento-ag-app/frontend/pages/board-member/meetings-votings/[meetingId]/topics/[id].vue?macro=true";
import { default as indexcnIy1gLh7rMeta } from "/builds/konsento-ag-app/frontend/pages/board-member/meetings-votings/index.vue?macro=true";
import { default as overviewrHnknu2B3FMeta } from "/builds/konsento-ag-app/frontend/pages/board-member/overview.vue?macro=true";
import { default as board_45memberWgy4oy6XirMeta } from "/builds/konsento-ag-app/frontend/pages/board-member.vue?macro=true";
import { default as coming_45soono9Pj5Thyw4Meta } from "/builds/konsento-ag-app/frontend/pages/coming-soon.vue?macro=true";
import { default as error_45404MP3yp4FdhoMeta } from "/builds/konsento-ag-app/frontend/pages/error/error-404.vue?macro=true";
import { default as error_45500X3JI8SdwqmMeta } from "/builds/konsento-ag-app/frontend/pages/error/error-500.vue?macro=true";
import { default as indexHK9rLmnZ1hMeta } from "/builds/konsento-ag-app/frontend/pages/error/index.vue?macro=true";
import { default as not_45authorizedeKUAFxL9riMeta } from "/builds/konsento-ag-app/frontend/pages/error/not-authorized.vue?macro=true";
import { default as under_45maintainance1jL4tNzOWSMeta } from "/builds/konsento-ag-app/frontend/pages/error/under-maintainance.vue?macro=true";
import { default as _91id_93pxWu0WAHEhMeta } from "/builds/konsento-ag-app/frontend/pages/external/meetings-votings/[id].vue?macro=true";
import { default as _91id_93a1MqeXVxzYMeta } from "/builds/konsento-ag-app/frontend/pages/external/meetings-votings/[meetingId]/topics/[id].vue?macro=true";
import { default as indexRg583fZRWlMeta } from "/builds/konsento-ag-app/frontend/pages/external/meetings-votings/index.vue?macro=true";
import { default as externalhT6M9a0GRXMeta } from "/builds/konsento-ag-app/frontend/pages/external.vue?macro=true";
import { default as indexnsAvKFIa4iMeta } from "/builds/konsento-ag-app/frontend/pages/index.vue?macro=true";
import { default as additional_45servicesi53kCVUfrxMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/additional-services.vue?macro=true";
import { default as _91id_93qZbjPfFLcwMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/documents/edit/[id].vue?macro=true";
import { default as indexMZBvTJalI8Meta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/documents/index.vue?macro=true";
import { default as newgCZaAM2e58Meta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/documents/new.vue?macro=true";
import { default as find_45start_45upsumJjqcPZJZMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/find-start-ups.vue?macro=true";
import { default as investmentsL3y3aoNCG1Meta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/investments.vue?macro=true";
import { default as investor_45settings0fH6zC2QdsMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/investor-settings.vue?macro=true";
import { default as indexkSwt7OmlKoMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/meetings-votings/[meetingId]/index.vue?macro=true";
import { default as meeting_45minutessmaQfm2gjbMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/meetings-votings/[meetingId]/meeting-minutes.vue?macro=true";
import { default as _91id_93SxcvpPj9QxMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/meetings-votings/[meetingId]/topics/[id].vue?macro=true";
import { default as indexh4nRF0UpWMMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/meetings-votings/index.vue?macro=true";
import { default as messages14NN58nP1WMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/messages.vue?macro=true";
import { default as _91id_93uDWlQHCyhqMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/news/details/[id].vue?macro=true";
import { default as indexoUxeDzRvqOMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/news/index.vue?macro=true";
import { default as overviewzuoBjWcHDBMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/overview.vue?macro=true";
import { default as _91investorFormId_93n5wYaT8no6Meta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/ownership-confirmation/[ownershipId]/investor/[investorFormId].vue?macro=true";
import { default as _91investorFormId_93XT5jTAIkOHMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/tax-holding/[taxHoldingId]/investor/[investorFormId].vue?macro=true";
import { default as transactionsxUxODpjDNhMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId]/transactions.vue?macro=true";
import { default as _91investorId_93Ld6fhHQ5CXMeta } from "/builds/konsento-ag-app/frontend/pages/investors/[investorId].vue?macro=true";
import { default as additional_45servicesRzrHa4yfrRMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/additional-services.vue?macro=true";
import { default as calculation_45sheets21K3UDyWtPMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/calculation-sheets.vue?macro=true";
import { default as communicationpYer5DIPsIMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/communication.vue?macro=true";
import { default as companytZADlKZdsQMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/company.vue?macro=true";
import { default as index6VUURAp0cdMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/index.vue?macro=true";
import { default as _91investorFormId_938vkA57tx3CMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/ownership-confirmation/[ownershipId]/investor/[investorFormId].vue?macro=true";
import { default as editHmxlTwT0CVMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/share-emissions/[emissionId]/ownership-confirmation/[ownershipId]/edit.vue?macro=true";
import { default as newOfZwvWSq8KMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/share-emissions/[emissionId]/ownership-confirmation/new.vue?macro=true";
import { default as editYbnkADuDIoMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/tax-holding/[taxholdingId]/edit.vue?macro=true";
import { default as _91investorFormId_93UsJAO51SngMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/tax-holding/[taxholdingId]/investor/[investorFormId].vue?macro=true";
import { default as newDD5rM8dQg3Meta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/tax-holding/new.vue?macro=true";
import { default as _91id_93sNy1aA0OpoMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/documents/edit/[id].vue?macro=true";
import { default as index2d0wTmOXKFMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/documents/index.vue?macro=true";
import { default as newosEM1YQJtAMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/documents/new.vue?macro=true";
import { default as _91id_93VbywdRGP4zMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/documents/signatures/[id].vue?macro=true";
import { default as indexk8dNfgsmW6Meta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/index.vue?macro=true";
import { default as signFV27ztWeBSMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/share-emissions/[emissionId]/ownership-confirmation/[ownershipId]/sign.vue?macro=true";
import { default as transferyb2HdIGu7aMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/share-emissions/[emissionId]/shares/transfer.vue?macro=true";
import { default as _91emissionId_939ejpqux39DMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/share-emissions/edit/[emissionId].vue?macro=true";
import { default as newe9lEClFA9XMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/share-emissions/new.vue?macro=true";
import { default as sign0uQpBIae99Meta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/tax-holding/[taxholdingId]/sign.vue?macro=true";
import { default as indexknazor4GXvMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/index.vue?macro=true";
import { default as invalid_45subscriptionSzPPVrWHcUMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/invalid-subscription.vue?macro=true";
import { default as attendance_45summaryCbuc6nghPJMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/attendance-summary.vue?macro=true";
import { default as board_45participantslNUydI9vEJMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/board-participants.vue?macro=true";
import { default as copyfY2e9OeYrWMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/copy.vue?macro=true";
import { default as index18UnrxjIpKMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/index.vue?macro=true";
import { default as meeting_45minutes_45formlQhSkYYOJ8Meta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/meeting-minutes-form.vue?macro=true";
import { default as meeting_45minutesilbH5BWlvZMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/meeting-minutes.vue?macro=true";
import { default as participantsrFYGv3XqBMMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/participants.vue?macro=true";
import { default as proxiesVsRghpTcbLMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/proxies.vue?macro=true";
import { default as representative_45summaryr95zA1ImgVMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/representative-summary.vue?macro=true";
import { default as editOR4KXXVupAMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/topics/[topicId]/edit.vue?macro=true";
import { default as indexD5Ob5kDXtdMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/topics/[topicId]/index.vue?macro=true";
import { default as participantspFcy4BcRNEMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/topics/[topicId]/participants.vue?macro=true";
import { default as new1AldkgSTHQMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/topics/new.vue?macro=true";
import { default as _91id_93pRBehWqyLJMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/edit/[id].vue?macro=true";
import { default as indexnwrrjIwbf6Meta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/index.vue?macro=true";
import { default as newZR94ixIgnnMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/new.vue?macro=true";
import { default as _91id_93rwV81wnUdLMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/news/details/[id].vue?macro=true";
import { default as _91id_93BpOXJBz8WbMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/news/edit/[id].vue?macro=true";
import { default as indexTzSBMaFEVWMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/news/index.vue?macro=true";
import { default as indexirKUydtW7FMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/order-additional-services/[id]/index.vue?macro=true";
import { default as submittedWL6cNTcOOCMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/order-additional-services/[id]/submitted.vue?macro=true";
import { default as _91id_93oZX57pE0hJMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/order-additional-services/[id].vue?macro=true";
import { default as indexrWECoUeZItMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/order-additional-services/index.vue?macro=true";
import { default as order_45additional_45serviceszT8tPlyTaeMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/order-additional-services.vue?macro=true";
import { default as overview4Jr7O91KtpMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/overview.vue?macro=true";
import { default as indexT3PNrgcTmbMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/share-ledger/index.vue?macro=true";
import { default as simulationsx8v0AdNQF8Meta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/simulations.vue?macro=true";
import { default as editMNSaRwsGCgMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/stake-holders/[id]/edit.vue?macro=true";
import { default as indexFXC5DItttZMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/stake-holders/[id]/index.vue?macro=true";
import { default as importkfXHc8BhO4Meta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/stake-holders/import.vue?macro=true";
import { default as index5apnnp1Tv4Meta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/stake-holders/index.vue?macro=true";
import { default as newd6OIIIv5BnMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/stake-holders/new.vue?macro=true";
import { default as editQQDsk4IQKEMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/transfers/[transferId]/edit.vue?macro=true";
import { default as indexPpG9oiQJvfMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/transfers/index.vue?macro=true";
import { default as newaF9bF25ocvMeta } from "/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/transfers/new.vue?macro=true";
import { default as new_45account_45typexqYxNFSonoMeta } from "/builds/konsento-ag-app/frontend/pages/new-account-type.vue?macro=true";
import { default as new_45companyn9grTfuEEBMeta } from "/builds/konsento-ag-app/frontend/pages/new-company.vue?macro=true";
import { default as notifications8V4wogduiSMeta } from "/builds/konsento-ag-app/frontend/pages/notifications.vue?macro=true";
import { default as post_45registrationBz5lQWWisoMeta } from "/builds/konsento-ag-app/frontend/pages/post-registration.vue?macro=true";
import { default as indexZIg2iGNvJKMeta } from "/builds/konsento-ag-app/frontend/pages/proxy/meetings-votings/[meetingId]/index.vue?macro=true";
import { default as _91id_93yDU0J1GinRMeta } from "/builds/konsento-ag-app/frontend/pages/proxy/meetings-votings/[meetingId]/topics/[id].vue?macro=true";
import { default as indexUAQHohGe6jMeta } from "/builds/konsento-ag-app/frontend/pages/proxy/meetings-votings/index.vue?macro=true";
import { default as proxyZnjA0cVq8xMeta } from "/builds/konsento-ag-app/frontend/pages/proxy.vue?macro=true";
import { default as publicafKDWivFgzMeta } from "/builds/konsento-ag-app/frontend/pages/public.vue?macro=true";
import { default as register_45co_45investment4aDreBjW4LMeta } from "/builds/konsento-ag-app/frontend/pages/register-co-investment.vue?macro=true";
import { default as register_45investorILd09s5CldMeta } from "/builds/konsento-ag-app/frontend/pages/register-investor.vue?macro=true";
import { default as under_45maintenancevi2bQIUdiNMeta } from "/builds/konsento-ag-app/frontend/pages/under-maintenance.vue?macro=true";
import { default as indexAZZQJDiwamMeta } from "/builds/konsento-ag-app/frontend/pages/user-profile/index.vue?macro=true";
export default [
  {
    name: "auth-create-password",
    path: "/auth/create-password",
    meta: create_45passwordBuzTFjJaxsMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/create-password.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordbDFksjZ3IkMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginpiF3Uk8kVeMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/login.vue")
  },
  {
    name: "auth-new-company",
    path: "/auth/new-company",
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/new-company.vue")
  },
  {
    name: "auth-new-register",
    path: "/auth/new-register",
    meta: new_45registerpHtpRGnKCiMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/new-register.vue")
  },
  {
    name: "auth-not-authorized",
    path: "/auth/not-authorized",
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/not-authorized.vue")
  },
  {
    name: "auth-post-registration",
    path: "/auth/post-registration",
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/post-registration.vue")
  },
  {
    name: "auth-register-investor",
    path: "/auth/register-investor",
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/register-investor.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerHCAu1xuOn5Meta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordYmzC7CqWdFMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/reset-password.vue")
  },
  {
    name: "auth-resgiter-co-investment",
    path: "/auth/resgiter-co-investment",
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/resgiter-co-investment.vue")
  },
  {
    name: "auth-verify-email",
    path: "/auth/verify-email",
    meta: verify_45emailbP3i62k4okMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/auth/verify-email.vue")
  },
  {
    name: "board-member",
    path: "/board-member",
    meta: board_45memberWgy4oy6XirMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/board-member.vue"),
    children: [
  {
    name: "board-member-meetings-votings-meetingId",
    path: "meetings-votings/:meetingId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/board-member/meetings-votings/[meetingId]/index.vue")
  },
  {
    name: "board-member-meetings-votings-meetingId-meeting-minutes",
    path: "meetings-votings/:meetingId()/meeting-minutes",
    component: () => import("/builds/konsento-ag-app/frontend/pages/board-member/meetings-votings/[meetingId]/meeting-minutes.vue")
  },
  {
    name: "board-member-meetings-votings-meetingId-topics-id",
    path: "meetings-votings/:meetingId()/topics/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/board-member/meetings-votings/[meetingId]/topics/[id].vue")
  },
  {
    name: "board-member-meetings-votings",
    path: "meetings-votings",
    component: () => import("/builds/konsento-ag-app/frontend/pages/board-member/meetings-votings/index.vue")
  },
  {
    name: "board-member-overview",
    path: "overview",
    component: () => import("/builds/konsento-ag-app/frontend/pages/board-member/overview.vue")
  }
]
  },
  {
    name: "coming-soon",
    path: "/coming-soon",
    component: () => import("/builds/konsento-ag-app/frontend/pages/coming-soon.vue")
  },
  {
    name: "error-error-404",
    path: "/error/error-404",
    component: () => import("/builds/konsento-ag-app/frontend/pages/error/error-404.vue")
  },
  {
    name: "error-error-500",
    path: "/error/error-500",
    component: () => import("/builds/konsento-ag-app/frontend/pages/error/error-500.vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/builds/konsento-ag-app/frontend/pages/error/index.vue")
  },
  {
    name: "error-not-authorized",
    path: "/error/not-authorized",
    component: () => import("/builds/konsento-ag-app/frontend/pages/error/not-authorized.vue")
  },
  {
    name: "error-under-maintainance",
    path: "/error/under-maintainance",
    component: () => import("/builds/konsento-ag-app/frontend/pages/error/under-maintainance.vue")
  },
  {
    name: "external",
    path: "/external",
    meta: externalhT6M9a0GRXMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/external.vue"),
    children: [
  {
    name: "external-meetings-votings-id",
    path: "meetings-votings/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/external/meetings-votings/[id].vue")
  },
  {
    name: "external-meetings-votings-meetingId-topics-id",
    path: "meetings-votings/:meetingId()/topics/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/external/meetings-votings/[meetingId]/topics/[id].vue")
  },
  {
    name: "external-meetings-votings",
    path: "meetings-votings",
    component: () => import("/builds/konsento-ag-app/frontend/pages/external/meetings-votings/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/konsento-ag-app/frontend/pages/index.vue")
  },
  {
    name: "investors-investorId",
    path: "/investors/:investorId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId].vue"),
    children: [
  {
    name: "investors-investorId-additional-services",
    path: "additional-services",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/additional-services.vue")
  },
  {
    name: "investors-investorId-documents-edit-id",
    path: "documents/edit/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/documents/edit/[id].vue")
  },
  {
    name: "investors-investorId-documents",
    path: "documents",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/documents/index.vue")
  },
  {
    name: "investors-investorId-documents-new",
    path: "documents/new",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/documents/new.vue")
  },
  {
    name: "investors-investorId-find-start-ups",
    path: "find-start-ups",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/find-start-ups.vue")
  },
  {
    name: "investors-investorId-investments",
    path: "investments",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/investments.vue")
  },
  {
    name: "investors-investorId-investor-settings",
    path: "investor-settings",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/investor-settings.vue")
  },
  {
    name: "investors-investorId-meetings-votings-meetingId",
    path: "meetings-votings/:meetingId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/meetings-votings/[meetingId]/index.vue")
  },
  {
    name: "investors-investorId-meetings-votings-meetingId-meeting-minutes",
    path: "meetings-votings/:meetingId()/meeting-minutes",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/meetings-votings/[meetingId]/meeting-minutes.vue")
  },
  {
    name: "investors-investorId-meetings-votings-meetingId-topics-id",
    path: "meetings-votings/:meetingId()/topics/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/meetings-votings/[meetingId]/topics/[id].vue")
  },
  {
    name: "investors-investorId-meetings-votings",
    path: "meetings-votings",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/meetings-votings/index.vue")
  },
  {
    name: "investors-investorId-messages",
    path: "messages",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/messages.vue")
  },
  {
    name: "investors-investorId-news-details-id",
    path: "news/details/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/news/details/[id].vue")
  },
  {
    name: "investors-investorId-news",
    path: "news",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/news/index.vue")
  },
  {
    name: "investors-investorId-overview",
    path: "overview",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/overview.vue")
  },
  {
    name: "investors-investorId-ownership-confirmation-ownershipId-investor-investorFormId",
    path: "ownership-confirmation/:ownershipId()/investor/:investorFormId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/ownership-confirmation/[ownershipId]/investor/[investorFormId].vue")
  },
  {
    name: "investors-investorId-tax-holding-taxHoldingId-investor-investorFormId",
    path: "tax-holding/:taxHoldingId()/investor/:investorFormId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/tax-holding/[taxHoldingId]/investor/[investorFormId].vue")
  },
  {
    name: "investors-investorId-transactions",
    path: "transactions",
    component: () => import("/builds/konsento-ag-app/frontend/pages/investors/[investorId]/transactions.vue")
  }
]
  },
  {
    name: "issuers-issuerId-additional-services",
    path: "/issuers/:issuerId()/additional-services",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/additional-services.vue")
  },
  {
    name: "issuers-issuerId-calculation-sheets",
    path: "/issuers/:issuerId()/calculation-sheets",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/calculation-sheets.vue")
  },
  {
    name: "issuers-issuerId-communication",
    path: "/issuers/:issuerId()/communication",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/communication.vue")
  },
  {
    name: "issuers-issuerId-company",
    path: "/issuers/:issuerId()/company",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/company.vue")
  },
  {
    name: "issuers-issuerId-confirmations",
    path: "/issuers/:issuerId()/confirmations",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/index.vue")
  },
  {
    name: "issuers-issuerId-confirmations-ownership-confirmation-ownershipId-investor-investorFormId",
    path: "/issuers/:issuerId()/confirmations/ownership-confirmation/:ownershipId()/investor/:investorFormId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/ownership-confirmation/[ownershipId]/investor/[investorFormId].vue")
  },
  {
    name: "issuers-issuerId-confirmations-share-emissions-emissionId-ownership-confirmation-ownershipId-edit",
    path: "/issuers/:issuerId()/confirmations/share-emissions/:emissionId()/ownership-confirmation/:ownershipId()/edit",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/share-emissions/[emissionId]/ownership-confirmation/[ownershipId]/edit.vue")
  },
  {
    name: "issuers-issuerId-confirmations-share-emissions-emissionId-ownership-confirmation-new",
    path: "/issuers/:issuerId()/confirmations/share-emissions/:emissionId()/ownership-confirmation/new",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/share-emissions/[emissionId]/ownership-confirmation/new.vue")
  },
  {
    name: "issuers-issuerId-confirmations-tax-holding-taxholdingId-edit",
    path: "/issuers/:issuerId()/confirmations/tax-holding/:taxholdingId()/edit",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/tax-holding/[taxholdingId]/edit.vue")
  },
  {
    name: "issuers-issuerId-confirmations-tax-holding-taxholdingId-investor-investorFormId",
    path: "/issuers/:issuerId()/confirmations/tax-holding/:taxholdingId()/investor/:investorFormId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/tax-holding/[taxholdingId]/investor/[investorFormId].vue")
  },
  {
    name: "issuers-issuerId-confirmations-tax-holding-new",
    path: "/issuers/:issuerId()/confirmations/tax-holding/new",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/confirmations/tax-holding/new.vue")
  },
  {
    name: "issuers-issuerId-documents-edit-id",
    path: "/issuers/:issuerId()/documents/edit/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/documents/edit/[id].vue")
  },
  {
    name: "issuers-issuerId-documents",
    path: "/issuers/:issuerId()/documents",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/documents/index.vue")
  },
  {
    name: "issuers-issuerId-documents-new",
    path: "/issuers/:issuerId()/documents/new",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/documents/new.vue")
  },
  {
    name: "issuers-issuerId-documents-signatures-id",
    path: "/issuers/:issuerId()/documents/signatures/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/documents/signatures/[id].vue")
  },
  {
    name: "issuers-issuerId-financial-instruments",
    path: "/issuers/:issuerId()/financial-instruments",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/index.vue")
  },
  {
    name: "issuers-issuerId-financial-instruments-share-emissions-emissionId-ownership-confirmation-ownershipId-sign",
    path: "/issuers/:issuerId()/financial-instruments/share-emissions/:emissionId()/ownership-confirmation/:ownershipId()/sign",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/share-emissions/[emissionId]/ownership-confirmation/[ownershipId]/sign.vue")
  },
  {
    name: "issuers-issuerId-financial-instruments-share-emissions-emissionId-shares-transfer",
    path: "/issuers/:issuerId()/financial-instruments/share-emissions/:emissionId()/shares/transfer",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/share-emissions/[emissionId]/shares/transfer.vue")
  },
  {
    name: "issuers-issuerId-financial-instruments-share-emissions-edit-emissionId",
    path: "/issuers/:issuerId()/financial-instruments/share-emissions/edit/:emissionId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/share-emissions/edit/[emissionId].vue")
  },
  {
    name: "issuers-issuerId-financial-instruments-share-emissions-new",
    path: "/issuers/:issuerId()/financial-instruments/share-emissions/new",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/share-emissions/new.vue")
  },
  {
    name: "issuers-issuerId-financial-instruments-tax-holding-taxholdingId-sign",
    path: "/issuers/:issuerId()/financial-instruments/tax-holding/:taxholdingId()/sign",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/financial-instruments/tax-holding/[taxholdingId]/sign.vue")
  },
  {
    name: "issuers-issuerId",
    path: "/issuers/:issuerId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/index.vue")
  },
  {
    name: "issuers-issuerId-invalid-subscription",
    path: "/issuers/:issuerId()/invalid-subscription",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/invalid-subscription.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-attendance-summary",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/attendance-summary",
    meta: attendance_45summaryCbuc6nghPJMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/attendance-summary.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-board-participants",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/board-participants",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/board-participants.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-copy",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/copy",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/copy.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/index.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-meeting-minutes-form",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/meeting-minutes-form",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/meeting-minutes-form.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-meeting-minutes",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/meeting-minutes",
    meta: meeting_45minutesilbH5BWlvZMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/meeting-minutes.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-participants",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/participants",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/participants.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-proxies",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/proxies",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/proxies.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-representative-summary",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/representative-summary",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/representative-summary.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-topics-topicId-edit",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/topics/:topicId()/edit",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/topics/[topicId]/edit.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-topics-topicId",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/topics/:topicId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/topics/[topicId]/index.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-topics-topicId-participants",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/topics/:topicId()/participants",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/topics/[topicId]/participants.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-meetingId-topics-new",
    path: "/issuers/:issuerId()/meetings-votings/:meetingId()/topics/new",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/[meetingId]/topics/new.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-edit-id",
    path: "/issuers/:issuerId()/meetings-votings/edit/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/edit/[id].vue")
  },
  {
    name: "issuers-issuerId-meetings-votings",
    path: "/issuers/:issuerId()/meetings-votings",
    meta: indexnwrrjIwbf6Meta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/index.vue")
  },
  {
    name: "issuers-issuerId-meetings-votings-new",
    path: "/issuers/:issuerId()/meetings-votings/new",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/meetings-votings/new.vue")
  },
  {
    name: "issuers-issuerId-news-details-id",
    path: "/issuers/:issuerId()/news/details/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/news/details/[id].vue")
  },
  {
    name: "issuers-issuerId-news-edit-id",
    path: "/issuers/:issuerId()/news/edit/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/news/edit/[id].vue")
  },
  {
    name: "issuers-issuerId-news",
    path: "/issuers/:issuerId()/news",
    meta: indexTzSBMaFEVWMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/news/index.vue")
  },
  {
    name: order_45additional_45serviceszT8tPlyTaeMeta?.name,
    path: "/issuers/:issuerId()/order-additional-services",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/order-additional-services.vue"),
    children: [
  {
    name: _91id_93oZX57pE0hJMeta?.name,
    path: ":id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/order-additional-services/[id].vue"),
    children: [
  {
    name: "issuers-issuerId-order-additional-services-id",
    path: "",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/order-additional-services/[id]/index.vue")
  },
  {
    name: "issuers-issuerId-order-additional-services-id-submitted",
    path: "submitted",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/order-additional-services/[id]/submitted.vue")
  }
]
  },
  {
    name: "issuers-issuerId-order-additional-services",
    path: "",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/order-additional-services/index.vue")
  }
]
  },
  {
    name: "issuers-issuerId-overview",
    path: "/issuers/:issuerId()/overview",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/overview.vue")
  },
  {
    name: "issuers-issuerId-share-ledger",
    path: "/issuers/:issuerId()/share-ledger",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/share-ledger/index.vue")
  },
  {
    name: "issuers-issuerId-simulations",
    path: "/issuers/:issuerId()/simulations",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/simulations.vue")
  },
  {
    name: "issuers-issuerId-stake-holders-id-edit",
    path: "/issuers/:issuerId()/stake-holders/:id()/edit",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/stake-holders/[id]/edit.vue")
  },
  {
    name: "issuers-issuerId-stake-holders-id",
    path: "/issuers/:issuerId()/stake-holders/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/stake-holders/[id]/index.vue")
  },
  {
    name: "issuers-issuerId-stake-holders-import",
    path: "/issuers/:issuerId()/stake-holders/import",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/stake-holders/import.vue")
  },
  {
    name: "issuers-issuerId-stake-holders",
    path: "/issuers/:issuerId()/stake-holders",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/stake-holders/index.vue")
  },
  {
    name: "issuers-issuerId-stake-holders-new",
    path: "/issuers/:issuerId()/stake-holders/new",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/stake-holders/new.vue")
  },
  {
    name: "issuers-issuerId-transfers-transferId-edit",
    path: "/issuers/:issuerId()/transfers/:transferId()/edit",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/transfers/[transferId]/edit.vue")
  },
  {
    name: "issuers-issuerId-transfers",
    path: "/issuers/:issuerId()/transfers",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/transfers/index.vue")
  },
  {
    name: "issuers-issuerId-transfers-new",
    path: "/issuers/:issuerId()/transfers/new",
    component: () => import("/builds/konsento-ag-app/frontend/pages/issuers/[issuerId]/transfers/new.vue")
  },
  {
    name: "new-account-type",
    path: "/new-account-type",
    component: () => import("/builds/konsento-ag-app/frontend/pages/new-account-type.vue")
  },
  {
    name: "new-company",
    path: "/new-company",
    component: () => import("/builds/konsento-ag-app/frontend/pages/new-company.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notifications8V4wogduiSMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/notifications.vue")
  },
  {
    name: "post-registration",
    path: "/post-registration",
    component: () => import("/builds/konsento-ag-app/frontend/pages/post-registration.vue")
  },
  {
    name: "proxy",
    path: "/proxy",
    meta: proxyZnjA0cVq8xMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/proxy.vue"),
    children: [
  {
    name: "proxy-meetings-votings-meetingId",
    path: "meetings-votings/:meetingId()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/proxy/meetings-votings/[meetingId]/index.vue")
  },
  {
    name: "proxy-meetings-votings-meetingId-topics-id",
    path: "meetings-votings/:meetingId()/topics/:id()",
    component: () => import("/builds/konsento-ag-app/frontend/pages/proxy/meetings-votings/[meetingId]/topics/[id].vue")
  },
  {
    name: "proxy-meetings-votings",
    path: "meetings-votings",
    component: () => import("/builds/konsento-ag-app/frontend/pages/proxy/meetings-votings/index.vue")
  }
]
  },
  {
    name: "public",
    path: "/public",
    meta: publicafKDWivFgzMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/public.vue")
  },
  {
    name: "register-co-investment",
    path: "/register-co-investment",
    component: () => import("/builds/konsento-ag-app/frontend/pages/register-co-investment.vue")
  },
  {
    name: "register-investor",
    path: "/register-investor",
    component: () => import("/builds/konsento-ag-app/frontend/pages/register-investor.vue")
  },
  {
    name: "under-maintenance",
    path: "/under-maintenance",
    meta: under_45maintenancevi2bQIUdiNMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/under-maintenance.vue")
  },
  {
    name: "user-profile",
    path: "/user-profile",
    meta: indexAZZQJDiwamMeta || {},
    component: () => import("/builds/konsento-ag-app/frontend/pages/user-profile/index.vue")
  }
]