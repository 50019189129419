import type { ResponseSuccess } from '@/types/http'
import type { Subscription, SubscriptionPlanItem, SubscriptionPlanScale, UpgradeSubscriptionPayload } from '@/types/subscription'

export const useSubscriptionStore = defineStore('subscription', () => {
  const authStore = useAuthStore()
  const subscription = ref<Subscription>()
  const plans = ref<SubscriptionPlanScale[]>()

  const currentSubscriptionPlan = computed(() => subscription.value?.subscription_plan_scale?.subscription_plan)
  const currentSubscriptionScale = computed(() => subscription.value?.subscription_plan_scale?.subscription_scale)
  const nextSubscription = computed(() => plans.value?.find((
    plan: SubscriptionPlanScale) =>
    plan.subscription_scale.amount_of_investors > (subscription.value?.amount_current_investors || 0)
    && plan.id > (subscription.value?.subscription_plan_scale_id || 0)),
  )

  async function getSubscription(companyId?: number) {
    try {
      const { data: respData } = await $api<ResponseSuccess>(`/companies/${companyId || authStore?.currentRoleId}/subscription`)

      subscription.value = respData || {}

      return respData
    }
    catch {}
  }

  async function fetchPlans() {
    try {
      const { data } = await $api<ResponseSuccess<Array<SubscriptionPlanScale>>>(`/plans`)

      plans.value = data || []

      return data
    }
    catch {
      return []
    }
  }

  async function fetchPlanScales() {
    const { data } = await $api<ResponseSuccess<Array<SubscriptionPlanItem>>>(`/plan-scales`)

    return data || []
  }

  async function submitSubscription(payload: UpgradeSubscriptionPayload) {
    try {
      await $api<ResponseSuccess>(`/companies/${authStore.currentCompany?.id}/subscriptions`, {
        method: 'POST',
        body: payload,
      })
      await getSubscription()
    }
    catch {
    }
  }

  async function cancelAccount() {
    const response = await $api<ResponseSuccess>(`/companies/${authStore.currentCompany?.id}/cancel-account`, {
      method: 'POST',
    })
    return response?.data
  }

  async function cancelSubscription() {
    const response = await $api<ResponseSuccess>(`/companies/${authStore.currentCompany?.id}/cancel-subscription`, {
      method: 'POST',
    })
    return response?.data
  }

  async function submitVoucher(code: string) {
    const response = await $api<ResponseSuccess>(`/companies/${authStore.currentCompany?.id}/submit-voucher`, {
      method: 'POST',
      body: { code },
    })
    return response?.data
  }

  return {
    subscription,
    plans,
    currentSubscriptionPlan,
    currentSubscriptionScale,
    nextSubscription,
    fetchPlans,
    getSubscription,
    submitSubscription,
    cancelAccount,
    cancelSubscription,
    submitVoucher,
    fetchPlanScales,
  }
})
